import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Rodal from 'rodal';
import styled, { createGlobalStyle } from 'styled-components';
import { getLocalStorage, Request } from '../services';

const Styles = createGlobalStyle`
	.rodal {
		z-index: 99999;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 calc(50% - 280px);
	}
	.rodal-dialog {
		border-radius: 12px;
		padding: 50px 30px 30px 30px;
		position: relative;
		margin: 0;
	}
	.rodal-close {
		height: 20px;
		outline: none;
		width: 20px;
	}
	.rodal-slideDown-enter {
		animation-timing-function: ease-in-out;
	}
	.rodal-close::before,
	.rodal-close::after {
		border-radius: 0;
	}
	body {
		overflow-y: ${props => (props.visible ? 'hidden' : 'scroll')};
	}
	@media screen and (max-width: 576px) {
		.rodal {
			padding: 0 10px;
		}
	}
`;
const Content = styled.div`
	& h3 {
		font-size: 24px;
		text-align: center;
	}
	& p {
		color: #818c99;
		text-align: center;
		font-size: 18px;
		line-height: 150%;
	}
`;

const Notification = () => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);
	const [notification, setNotification] = useState({
		description: '',
		image: '',
		title: '',
		url: '',
	});
	const { description, image, title, url } = notification;
	useEffect(() => {
		const { token } = getLocalStorage(['token']);
		if (token) {
			Request()
				.get(`/notification?token=${token}`)
				.then(response => {
					const { status, data } = response.data;
					if (
						status === 1 &&
						lodash.get(data, 'title') &&
						lodash.get(data, 'description')
					) {
						setVisible(true);
						setNotification(data);
					}
				})
				.catch(e => console.log(e));
		}
	}, []);
	return (
		<>
			<Rodal
				visible={visible}
				onClose={() => setVisible(!visible)}
				closeOnEsc={true}
				animation="slideDown"
				customStyles={{
					height: 'auto',
					width: '100%',
				}}>
				<Content>
					<h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
					<p dangerouslySetInnerHTML={{ __html: description }}></p>
				</Content>
			</Rodal>
			<Styles visible={visible} />
		</>
	);
};
export default Notification;
