import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { PasswordInput } from '../components';
import ArrowDown from '../images/arrow-down.svg';
import DefaultUser from '../images/default-user.svg';
import { loaded, loading, logout } from '../redux';
import { removeLocalStorage, Request, useDetect } from '../services';
import Modal from './modal';

const Container = styled.div`
	display: flex;
	justify-content: center;
	cursor: pointer;
	height: 43px;
`;
const Name = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const Image = styled.img`
	margin: 0 10px 0 0;
	width: 43px;
	height: 43px;
	border-radius: 50%;
`;
const Down = styled.img`
	width: 10px;
	height: 7px;
	margin-left: 7px;
`;
const ModalBox = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	& button {
		margin: 10px;
	}
`;
const Box = styled.form`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	& .button {
		width: 100%;
	}
`;
const ModalTitle = styled.h2`
	font-size: 28px;
	margin: 0 0 24px 0;
`;
const Option = styled.li`
	white-space: nowrap;
	padding: 13px 18px;
	cursor: pointer;
	&:hover {
		background: rgba(110, 120, 146, 0.15);
	}
	& p {
		height: 100%;
		width: 100%;
	}
`;
const Menu = styled.ul`
	border-radius: 8px;
	top: 65px;
	right: 0;
	box-shadow: 0 1px 8px rgba(110, 120, 146, 0.5);
	opacity: ${p => (p.active ? 1 : 0)};
	visibility: ${p => (p.active ? 'visible' : 'hidden')};
	transform: translateY(${p => (p.active ? '0' : '-20px')});
	transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
	@media (max-width: 768px) {
		top: 54px;
	}
`;
const Button = styled.button`
	height: 50px;
	border-radius: 8px;
	width: 200px;
`;

const User = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { firstname, avatar, lastname } = useSelector(state => state.user);
	const ref = useRef(null);
	const { REACT_APP_IMAGE_ALT: ImageAlt, REACT_APP_MEDIA_URL: MediaUrl } =
		process.env;
	const [active, setActive] = useDetect(ref, false);

	const [visible, setVisible] = useState(false);
	const [open, setOpen] = useState(false);
	const [forgot, setForgot] = useState({
		password: '',
		confirm_password: '',
		password_error: false,
		confirm_password_error: false,
		in_correct: false,
	});
	const {
		password,
		password_error,
		confirm_password,
		confirm_password_error,
		in_correct,
	} = forgot;
	const onClose = e => {
		e.stopPropagation();
		setActive(false);
		setOpen(!open);
		setForgot({
			password: '',
			confirm_password: '',
			password_error: false,
			confirm_password_error: false,
			in_correct: false,
		});
	};
	const onToggle = e => {
		e.stopPropagation();
		setActive(false);
		setVisible(!visible);
	};
	const redirectToProfile = e => {
		e.stopPropagation();
		setActive(false);
		history.push('/profile');
	};
	const logOutUser = async () => {
		await dispatch(loading());
		Request()
			.post('/logout', {})
			.then(response => {
				const { message } = response.data;
				if (message === 'Successfully logged out') {
					dispatch(logout());
					removeLocalStorage(['token']);
					history.push('/');
				}
			})
			.catch(e => console.log(e))
			.finally(() => dispatch(loaded()));
	};
	const forgotPassword = async e => {
		e.preventDefault();
		if (
			password.length > 7 &&
			confirm_password.length > 7 &&
			password === confirm_password
		) {
			await dispatch(loading());
			const formData = new FormData();
			formData.append('password', password);
			formData.append('confirm_password', confirm_password);
			Request()
				.post('/forgotupdatepassword', formData)
				.then(response => {
					const { status } = response.data;
					if (status === 1) {
						toast.success(t('app.password-changed'));
						setOpen(false);
					} else {
						toast.warning(t('app.password.not-changed'));
					}
				})
				.catch(() => {
					toast.warning(t('app.password.not-changed'));
				})
				.finally(() => dispatch(loaded()));
		} else if (password.length < 8) {
			setForgot({ ...forgot, password_error: true });
		} else if (confirm_password.length < 8) {
			setForgot({ ...forgot, confirm_password_error: true });
		} else if (password !== confirm_password) {
			setForgot({ ...forgot, in_correct: true });
		}
	};
	const onError = e => {
		e.target.onerror = null;
		e.target.src = DefaultUser;
	};
	const onClick = () => setActive(!active);
	return (
		<>
			<Container className="p-relative">
				<Name title={`${firstname} - ${lastname}`} onClick={onClick}>
					<Image
						className="overflow-0"
						src={MediaUrl + '/' + avatar}
						onError={onError}
						alt={ImageAlt}
					/>
					<h4 className="f15 f600 margin-0">
						{firstname ? firstname : t('app.no-name')}
					</h4>
					<Down alt={ImageAlt} src={ArrowDown} />
				</Name>
				<Menu
					className="bg-white p-absolute padding-0 margin-0 overflow-0"
					ref={ref}
					active={active}>
					<Option className="f600 f15 bg-0 trans-5" onClick={redirectToProfile}>
						<p className="margin-0">{t('app.profile')}</p>
					</Option>
					<Option className="f600 f15 bg-0 trans-5" onClick={onClose}>
						<p className="margin-0 f-blue">{t('app.forgot-password')}</p>
					</Option>
					<Option className="f600 f15 bg-0 trans-5" onClick={onToggle}>
						<p className="margin-0 f-red">{t('app.log-out')}</p>
					</Option>
				</Menu>
			</Container>
			<Modal visible={visible} onToggle={onToggle}>
				<ModalTitle className="f-center padding-0">{t('app.exit')}</ModalTitle>
				<h3 className="f20 f500 f-center f-grey">
					{t('app.modal-description')}
				</h3>
				<ModalBox>
					<Button
						onClick={onToggle}
						className="f16 f500 bg-grey f-white border-0">
						{t('app.cancel')}
					</Button>
					<Button
						onClick={logOutUser}
						className="f16 f500 bg-blue f-white border-0">
						{t('app.log-out')}
					</Button>
				</ModalBox>
			</Modal>
			<Modal visible={open} onToggle={onClose}>
				<Box>
					<ModalTitle className="f-center padding-0">
						{t('app.forgot-password')}
					</ModalTitle>
					<PasswordInput
						placeholder={t('app.password')}
						value={password}
						onChange={e => setForgot({ ...forgot, password: e.target.value })}
						onFocus={() =>
							setForgot({ ...forgot, password_error: false, in_correct: false })
						}
						background={'bg-light-grey'}
					/>
					{password_error ? (
						<p className="f-red f700 f18" style={{ marginTop: 0 }}>
							{t('app.password-error')}
						</p>
					) : null}
					<PasswordInput
						placeholder={t('app.confirm-password')}
						value={confirm_password}
						onChange={e =>
							setForgot({ ...forgot, confirm_password: e.target.value })
						}
						background={'bg-light-grey'}
						onFocus={() =>
							setForgot({
								...forgot,
								confirm_password_error: false,
								in_correct: false,
							})
						}
					/>
					{confirm_password_error ? (
						<p className="f-red f700 f18" style={{ marginTop: 0 }}>
							{t('app.confirm-password-error')}
						</p>
					) : null}
					{in_correct ? (
						<p className="f-red f700 f18" style={{ marginTop: 0 }}>
							{t('app.in-correct')}
						</p>
					) : null}
					<Button
						onClick={forgotPassword}
						type="submit"
						className="f18 f500 bg-blue button f-white border-0">
						{t('app.forgot')}
					</Button>
				</Box>
			</Modal>
		</>
	);
};
export default User;
