import Ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-haskell';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-pascal';
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-rust';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-kuroir';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-solarized_light';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/theme-xcode';
import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReactSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { loaded, loading } from '../redux';
import { Request } from '../services';
import Pagination from './pagination';
import RCPagination from './rc-pagination';

const Container = styled.div`
	padding: 20px 0 70px 0;
	& .info-problem {
		border: 1px solid var(--light-grey);
		padding: 15px;
		border-radius: 16px;
	}
`;
const AceContainer = styled.div`
	padding: 20px;
	background: var(--light-grey);
	border-radius: 8px;
	& .ace-header-menu {
		display: flex;
		justify-content: space-between;
		& .ace-header-container {
			display: flex;
			flex-wrap: wrap;
			z-index: 10;
			padding: 0 0 10px 0;
			@media (max-width: 650px) {
				flex-direction: column;
				align-items: flex-end;
			}
			& .change-editor-item {
				margin: 0 5px;
				&:first-child {
					margin: 0 5px 0 0;
					@media (max-width: 650px) {
						margin: 0 0 10px;
					}
				}
				&:last-child {
					margin: 0 0 0 5px;
					@media (max-width: 650px) {
						margin: 10px 0 0 0;
					}
				}
				@media (max-width: 650px) {
					margin: 0;
				}
			}
		}
	}
	& .send-code {
		display: flex;
		justify-content: flex-end;
		padding: 20px 0 0 0;
		& button {
			background: var(--blue);
			color: var(--white);
			border: none;
			outline: none;
			border-radius: 6px;
			height: 40px;
			width: 150px;
			font-weight: 600;
			font-size: 18px;
			@media (max-width: 650px) {
				width: 100%;
			}
		}
	}
	@media (max-width: 576px) {
		padding: 10px;
	}
`;
const Table = styled.div`
	overflow-x: scroll;
	margin: 20px 0;
	&::-webkit-scrollbar {
		height: 4px;
	}
	& table {
		border-collapse: collapse;
		border-spacing: 0;
		min-width: 100%;
		& td,
		& th {
			height: 50px;
			font-size: 20px;
			font-weight: 500;
			white-space: nowrap;
			padding: 10px 20px;
		}
	}
`;
const Ratings = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 15px 0;
	& a {
		width: 120px;
		height: 40px;
		border: 1px solid var(--grey);
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
const Title = styled.h3`
	font-size: 32px;
	margin: calc(2vw + 20px) 0 20px 0;
	@media (max-width: 768px) {
		font-size: 24px;
	}
`;
const CustomOption = styled.div`
	width: 100%;
	height: 35px;
	padding: 7px 12px;
	background: ${p => (p.isSelected ? 'var(--blue)' : 'var(--white)')};
	cursor: pointer;
	color: ${p => (p.isSelected ? 'var(--white)' : 'var(--bold)')};
	transition: 400ms;
	display: flex;
	align-items: center;
	& img {
		width: 24px;
		height: 24px;
		margin: 0 10px 0 0;
	}
	&:hover {
		background: var(--blue);
		color: var(--white);
	}
`;
const styles = {
	option: (provided, state) => ({
		...provided,
		'color': state.isSelected ? 'var(--white)' : 'var(--bold)',
		'background': state.isSelected ? 'var(--blue)' : 'var(--white)',
		'width': 170,
		'cursor': 'pointer',
		'height': 35,
		'padding': '6px 12px',
		'transition': '0.3s',
		'fontSize': 16,
		'fontWeight': 600,
		':hover': {
			background: 'var(--blue)',
			color: 'var(--white)',
		},
	}),
	singleValue: provided => ({
		...provided,
		color: 'var(--bold)',
		fontWeight: 600,
	}),
	control: provided => ({
		...provided,
		width: 170,
		outline: 'none',
		borderRadius: 6,
		border: 'none',
		boxShadow: 'none',
		cursor: 'pointer',
		background: 'var(--white)',
		height: 35,
		minHeight: 35,
		margin: 0,
		padding: 0,
		transition: '400ms',
		fontSize: 16,
		fontWeight: 600,
	}),
	menu: provided => ({
		...provided,
		margin: 0,
		padding: 0,
		borderRadius: 6,
		width: 170,
		zIndex: 10,
		top: 35,
		background: 'var(--light-brown)',
		overflow: 'hidden',
		border: 'none',
	}),
	menuList: provided => ({
		...provided,
		'padding': 0,
		'border': 'none',
		'::-webkit-scrollbar': {
			width: 4,
		},
	}),
	indicatorSeparator: provided => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: provided => ({
		...provided,
		'color': 'var(--bold)',
		'padding': '4px 4px 4px 0',
		':hover': {
			color: 'var(--bold)',
		},
	}),
	valueContainer: provided => ({
		...provided,
		padding: '0 0 0 10px',
		height: 30,
		display: 'flex',
	}),
};
const fontStyles = {
	option: (provided, state) => ({
		...provided,
		'color': state.isSelected ? 'var(--white)' : 'var(--bold)',
		'background': state.isSelected ? 'var(--blue)' : 'var(--white)',
		'width': 80,
		'cursor': 'pointer',
		'height': 35,
		'padding': '6px 12px',
		'transition': '0.3s',
		'fontSize': 16,
		'fontWeight': 600,
		':hover': {
			background: 'var(--blue)',
			color: 'var(--white)',
		},
	}),
	singleValue: provided => ({
		...provided,
		color: 'var(--bold)',
		fontWeight: 600,
	}),
	control: provided => ({
		...provided,
		width: 80,
		outline: 'none',
		borderRadius: 6,
		border: 'none',
		boxShadow: 'none',
		cursor: 'pointer',
		background: 'var(--white)',
		height: 35,
		minHeight: 35,
		margin: 0,
		padding: 0,
		transition: '400ms',
		fontSize: 16,
		fontWeight: 600,
	}),
	menu: provided => ({
		...provided,
		margin: 0,
		padding: 0,
		borderRadius: 6,
		width: 80,
		zIndex: 10,
		top: 35,
		background: 'var(--light-brown)',
		overflow: 'hidden',
		border: 'none',
	}),
	menuList: provided => ({
		...provided,
		'padding': 0,
		'border': 'none',
		'::-webkit-scrollbar': {
			width: 4,
		},
	}),
	indicatorSeparator: provided => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: provided => ({
		...provided,
		'color': 'var(--bold)',
		'padding': '4px 4px 4px 0',
		':hover': {
			color: 'var(--bold)',
		},
	}),
	valueContainer: provided => ({
		...provided,
		padding: '0 0 0 10px',
		height: 30,
		display: 'flex',
	}),
};

const Option = ({
	innerProps,
	innerRef,
	data: { display_name, image },
	isSelected,
	data,
}) => (
	<CustomOption
		isSelected={isSelected}
		ref={innerRef}
		{...innerProps}
		className="f16 f600">
		<img src={`https://contest2.yarbek.uz//storage/${image}`} />
		<span>{display_name}</span>
	</CustomOption>
);

const Editor = ({
	problems,
	problem,
	compilers,
	difference,
	setState,
	children,
}) => {
	const { id, slug } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const [results, setResults] = useState([]);
	const [statusCodes, setStatusCodes] = useState({});
	const [activePage, setActivePage] = useState(1);
	const [total, setTotal] = useState(1);
	const {
		body,
		input_type,
		memory_limit,
		output_type,
		pcode,
		status,
		time_limit,
		title,
		type_id,
		test_case,
	} = problem;
	const [value, setValue] = useState(``);
	const [size, setSize] = useState({
		label: '14px',
		value: 14,
	});
	const [sizes, setSizes] = useState([
		{
			label: '30px',
			value: 30,
		},
		{
			label: '28px',
			value: 28,
		},
		{
			label: '26px',
			value: 26,
		},
		{
			label: '24px',
			value: 24,
		},
		{
			label: '23px',
			value: 23,
		},
		{
			label: '22px',
			value: 22,
		},
		{
			label: '21px',
			value: 21,
		},
		{
			label: '20px',
			value: 20,
		},
		{
			label: '19px',
			value: 19,
		},
		{
			label: '18px',
			value: 18,
		},
		{
			label: '17px',
			value: 17,
		},
		{
			label: '16px',
			value: 16,
		},
		{
			label: '15px',
			value: 15,
		},
		{
			label: '14px',
			value: 14,
		},
		{
			label: '13px',
			value: 13,
		},
		{
			label: '12px',
			value: 12,
		},
		{
			label: '11px',
			value: 11,
		},
		{
			label: '10px',
			value: 10,
		},
	]);
	const [themes, setThemes] = useState([
		{
			label: 'Kuroir',
			value: 'kuroir',
		},
		{
			label: 'Monokai',
			value: 'monokai',
		},
		{
			label: 'Solarized Dark',
			value: 'solarized_dark',
		},
		{
			label: 'Solarized Light',
			value: 'solarized_light',
		},
		{
			label: 'Terminal',
			value: 'terminal',
		},
		{
			label: 'Textmate',
			value: 'textmate',
		},
		{
			label: 'Twilight',
			value: 'twilight',
		},
		{
			label: 'Xcode',
			value: 'xcode',
		},
	]);
	const [language, setLanguage] = useState({
		label: '',
		value: '',
		defaultValue: '',
		name: '',
		id: '',
	});
	const [theme, setTheme] = useState({
		label: 'Textmate',
		value: 'textmate',
	});
	const changeLanguage = language => {
		setLanguage(language);
		localStorage.setItem('editor-language', JSON.stringify(language));
		Ace.edit('editor').getSession().setMode(`ace/mode/${language.value}`);
	};
	const changeTheme = theme => {
		setTheme(theme);
		localStorage.setItem('editor-theme', JSON.stringify(theme));
		Ace.edit('editor').setTheme(`ace/theme/${theme.value}`);
	};
	const changeSize = size => {
		setSize(size);
		localStorage.setItem('editor-font-size', JSON.stringify(size));
	};
	const getResult = (page = 1) => {
		Request()
			.get(`/results?problem_id=${id}&page=${page}`)
			.then(response => {
				const { status, info, data } = response.data;
				if (status === 1 && typeof data === 'object') {
					const results = Array.isArray(data.data) ? data.data : [];
					setTotal(data.total);
					setResults(results);
					setStatusCodes(info);
				} else {
					setResults([]);
				}
			})
			.catch(() => setResults([]));
	};
	const getNextResult = async page => {
		await setActivePage(page);
		getResult(page);
	};
	useEffect(() => {
		getResult();
		const fontSize = JSON.parse(localStorage.getItem('editor-font-size')) || {
			label: '14px',
			value: 14,
		};
		setSize(fontSize);
		const theme = JSON.parse(localStorage.getItem('editor-theme')) || {
			label: 'Textmate',
			value: 'textmate',
		};
		setTheme(theme);
	}, []);
	useEffect(() => {
		const language =
			JSON.parse(localStorage.getItem('editor-language')) || compilers[0];
		setLanguage(language);
	}, [compilers]);
	const sendCode = async () => {
		if (value.length) {
			await dispatch(loading());
			Request()
				.post('/submit-solution', {
					problem_id: parseInt(id),
					contest_slug: slug,
					compiler_id: language.id,
					solution: value,
				})
				.then(response => {
					const { status } = response.data;
					if (status) {
						toast.success(t('online-contest-start.answer-not-saved'));
					} else {
						toast.warning(t('online-contest-start.answer-saved'));
					}
				})
				.catch(() => toast.warning(t('online-contest-start.answer-saved')))
				.finally(() => {
					dispatch(loaded());
					setActivePage(1);
					getResult();
				});
		}
	};
	const current = problems.findIndex(item => item.id === parseInt(id)) + 1;
	return (
		<Container>
			<RCPagination
				total={problems.length}
				current={current ? current : null}
				problems={problems}
				onChange={page =>
					history.push(
						`/online-contests/${slug}/problems/${problems[page - 1].id}`,
					)
				}
			/>
			<Title>{title}</Title>
			{children}
			<p
				className="f-grey f20 f500"
				dangerouslySetInnerHTML={{ __html: body }}></p>
			<h3 className="f-blue f18 f500">
				{t('online-contest-start.input-data')}
			</h3>
			<p
				className="f-grey f20 f500"
				dangerouslySetInnerHTML={{ __html: input_type }}></p>
			<h3 className="f-blue f18 f500">
				{t('online-contest-start.output-data')}
			</h3>
			<p
				className="f-grey f20 f500"
				dangerouslySetInnerHTML={{ __html: output_type }}></p>
			{test_case.length ? (
				<>
					<h3 className="f-blue f18 f500">
						{t('online-contest-start.problems')}
					</h3>
					<Table>
						<table>
							<thead>
								<tr className="bg-light">
									<th style={{ border: '1px solid var(--light-grey)' }}>#</th>
									<th
										style={{ border: '1px solid var(--light-grey)' }}
										className="f20 f500 f-left">
										{t('online-contest-start.input-info')}
									</th>
									<th
										style={{ border: '1px solid var(--light-grey)' }}
										className="f20 f500 f-left">
										{t('online-contest-start.output-info')}
									</th>
									<th
										style={{ border: '1px solid var(--light-grey)' }}
										className="f20 f500 f-left">
										{t('online-contest-start.description')}
									</th>
								</tr>
							</thead>
							<tbody>
								{test_case.map(
									({ sample_input, sample_output, sample_note }, index) => {
										return (
											<tr key={index} className="bg-white">
												<td
													style={{ border: '1px solid var(--light-grey)' }}
													className="f-center">
													{index + 1}
												</td>
												<td
													style={{ border: '1px solid var(--light-grey)' }}
													className="f20 f500"
													dangerouslySetInnerHTML={{
														__html: sample_input,
													}}></td>
												<td
													style={{ border: '1px solid var(--light-grey)' }}
													dangerouslySetInnerHTML={{ __html: sample_output }}
													className="f20 f500"></td>
												<td
													style={{ border: '1px solid var(--light-grey)' }}
													dangerouslySetInnerHTML={{ __html: sample_note }}
													className="f20 f500"></td>
											</tr>
										);
									},
								)}
							</tbody>
						</table>
					</Table>
				</>
			) : null}
			<Ratings>
				<Link
					className="f18 f600 f-grey"
					to={`/online-contests/${slug}/results`}>
					{t('online-contest-start.rating')}
				</Link>
			</Ratings>
			<AceContainer>
				<div className="ace-header-menu">
					<h3 className="f20 f600 margin-0">
						{t('online-contest-start.code')}
					</h3>
					<div className="ace-header-container">
						<div className="change-editor-item">
							<ReactSelect
								styles={fontStyles}
								options={sizes}
								value={size}
								onChange={size => changeSize(size)}
								isSearchable={false}
								menuPlacement="auto"
							/>
						</div>
						<div className="change-editor-item">
							<ReactSelect
								styles={styles}
								options={compilers}
								value={language}
								onChange={language => changeLanguage(language)}
								isSearchable={false}
								components={{ Option: Option }}
								getOptionValue={data => data['name']}
								menuPlacement="auto"
							/>
						</div>
						<div className="change-editor-item">
							<ReactSelect
								styles={styles}
								options={themes}
								value={theme}
								getOptionValue={data => data['value']}
								onChange={theme => changeTheme(theme)}
								isSearchable={false}
								menuPlacement="auto"
							/>
						</div>
					</div>
				</div>
				<AceEditor
					placeholder={t('online-contest-start.editor-placeholder')}
					mode={language.value}
					theme={theme.value}
					name="editor"
					// defaultValue={language.defaultValue}
					// onLoad={onLoad}
					onChange={value => setValue(value)}
					fontSize={size.value}
					style={{
						width: '100%',
						height: '400px',
					}}
					showPrintMargin={true}
					showGutter={true}
					tabSize={4}
					highlightActiveLine={true}
					enableBasicAutocompletion={true}
					enableSnippets={true}
					wrapEnabled={true}
					enableLiveAutocompletion={true}
					value={value}
					setOptions={{
						enableBasicAutocompletion: true,
						enableLiveAutocompletion: true,
						enableSnippets: true,
						showLineNumbers: true,
						tabSize: 4,
						mode: language.value,
						theme: theme.value,
					}}
					editorProps={{
						$blockScrolling: true,
					}}
				/>
				<div className="send-code">
					<button onClick={sendCode}>
						{t('online-contest-start.editor-send')}
					</button>
				</div>
			</AceContainer>
			{results.length ? (
				<>
					<h3 className="f24 f600">
						{t('online-contest-start.attempt-title')}
					</h3>
					<Table>
						<table>
							<thead>
								<tr className="bg-light">
									<th className="f20 f500 f-center">
										{t('online-contest-start.attempt')}
									</th>
									<th className="f20 f500 f-center">
										{t('online-contest-start.status-code')}
									</th>
									<th className="f20 f500 f-center">
										{t('online-contest-start.programming-language')}
									</th>
									<th className="f20 f500 f-center">
										{t('online-contest-start.time-limit')}
									</th>
									<th className="f20 f500 f-center">
										{t('online-contest-start.memory-limit')}
									</th>
									<th className="f20 f500 f-center">
										{t('online-contest-start.result-time')}
									</th>
									<th className="f20 f500 f-center">
										{t('online-contest-start.result-date')}
									</th>
								</tr>
							</thead>
							<tbody>
								{results.map(
									(
										{
											compiler_id,
											time,
											max_memory,
											status,
											test_case,
											created_at,
										},
										index,
									) => {
										const languages = compilers.find(
											compiler => compiler.id === compiler_id,
										);
										const language = languages ? languages.display_name : '';
										const statusCode = statusCodes[status];
										return (
											<tr className="bg-white" key={index}>
												<td className="f20 f500 f-center">
													№{total - (activePage - 1) * 25 - index}
												</td>
												<td
													className={
														statusCode === 'Accepted'
															? 'f20 f500 f-center f-green'
															: statusCode === 'Pending'
															? 'f20 f500 f-center f-blue'
															: 'f20 f500 f-center f-red'
													}>
													{statusCode}{' '}
													{status === -1 ||
													status === 4 ||
													status === 1 ||
													status === 3 ||
													status === 2
														? `(test - ${test_case})`
														: null}
												</td>
												<td className="f20 f500 f-center">{language}</td>
												<td className="f20 f500 f-center">{time}</td>
												<td className="f20 f500 f-center">{max_memory}</td>
												<td className="f20 f500 f-center">
													{created_at.slice(11, 16)}
												</td>
												<td className="f20 f500 f-center">
													{created_at.slice(0, 10).replace(/-/g, '/')}
												</td>
											</tr>
										);
									},
								)}
							</tbody>
						</table>
					</Table>
					<Pagination
						totalItems={Math.ceil(total / 25)}
						activePage={activePage}
						onChange={page => getNextResult(page)}
					/>
				</>
			) : null}
		</Container>
	);
};
export default Editor;
