import styled from 'styled-components';

const SmallLogo = styled.img`
	width: 27px;
	display: none;
	@media (max-width: 576px) {
		display: inline-block;
	}
`;
export default SmallLogo;
