import styled from 'styled-components';

const Head = styled.section`
	height: 88px;
	position: fixed;
	top: ${p => (p.scroll ? '-88px' : '0')};
	left: 0;
	background: var(--white);
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 9999;
	transition: top 0.4s ease-in-out;
	border-bottom: 1px solid rgba(110, 120, 146, 0.15);
	@media (max-width: 768px) {
		height: 65px;
		top: ${p => (p.scroll ? '-65px' : '0')};
	}
`;
export default Head;
