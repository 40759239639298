import React from 'react';
import Rodal from 'rodal';
import styled, { createGlobalStyle } from 'styled-components';

const Styles = createGlobalStyle`
	body {
		overflow-y: ${p => (p.visible ? 'hidden !important' : 'initial')};
	}
`;
const Container = styled.div`
	& .rodal {
		z-index: 99999;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 calc(50% - 280px);
	}
	& .rodal-dialog {
		border-radius: 12px;
		padding: 50px 15px 15px 15px;
		position: relative;
		margin: 0;
	}
	& .rodal-close {
		height: 20px;
		outline: none;
		width: 20px;
	}
	& .rodal-slideDown-enter {
		animation-timing-function: ease-in-out;
	}
	& .rodal-close::before,
	& .rodal-close::after {
		border-radius: 0;
	}
	@media screen and (max-width: 576px) {
		& .rodal {
			padding: 0 10px;
		}
	}
`;

const Modal = ({ visible, onToggle, children }) => (
	<Container>
		<Rodal
			visible={visible}
			onClose={onToggle}
			closeOnEsc={true}
			animation="slideDown"
			customStyles={{
				height: 'auto',
				width: '100%',
			}}>
			{children}
		</Rodal>
		<Styles visible={visible} />
	</Container>
);
export default Modal;
