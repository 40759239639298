import React from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';

const Styles = createGlobalStyle`
   body {
      overflow: hidden !important;
   }
`;
const rotate = keyframes`
   100% {
      transform: rotate(360deg);
   }
`;
const dash = keyframes`
   0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
   }
   50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
   }
   100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
   }
`;
const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	z-index: 999999;
	background: rgba(0, 0, 0, 0.4);
	& svg {
		animation: ${rotate} 2s linear infinite;
		& circle {
			animation: ${dash} 1.75s ease-in-out infinite;
		}
	}
`;

const Loading = () => (
	<Container>
		<svg height="70" width="70" viewBox="0 0 50 50" viewBox="0 0 50 50">
			<circle
				cx="25"
				cy="25"
				fill="none"
				stroke="#ffffff"
				strokeWidth="4"
				strokeLinecap="round"
				r="20"></circle>
		</svg>
		<Styles />
	</Container>
);
export default Loading;
