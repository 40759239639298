import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Time = styled.div`
	font-size: 18px;
	font-weight: 600;
	width: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--blue);
	height: 44px;
	display: flex;
	align-items: center;
`;

const ContestTimer = ({ difference = 10000, setStatus }) => {
	const { t } = useTranslation();
	const [time, setTime] = useState(difference);
	const day = parseInt(time / (1000 * 60 * 60 * 24));
	const dayRemain = time % (1000 * 60 * 60 * 24);
	const hour = parseInt(dayRemain / (1000 * 60 * 60));
	const hourRemain = parseInt(dayRemain % (1000 * 60 * 60));
	const minute = parseInt(hourRemain / (1000 * 60));
	const minuteRemain = parseInt(hourRemain % (1000 * 60));
	const second = parseInt(minuteRemain / 1000);
	useEffect(() => {
		const tick = () => {
			if (time > 0) {
				setTime(time - 1000);
			} else {
				setStatus(false);
			}
		};
		const timer = setInterval(_ => tick(), 1000);
		return () => clearInterval(timer);
	}, [time]);
	return (
		<Time>
			{day.toString().padStart(2, '0')}:{hour.toString().padStart(2, '0')}:
			{minute.toString().padStart(2, '0')}:{second.toString().padStart(2, '0')}
		</Time>
	);
};
export default ContestTimer;
