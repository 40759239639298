import lodash from 'lodash';
import RCPagination from 'rc-pagination';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	justify-content: center;
	padding: 0 10%;
	@media screen and (max-width: 576px) {
		padding: 0 5%;
	}
	@media screen and (max-width: 576px) {
		padding: 0;
	}
	& .rc-pagination {
		display: flex;
		position: relative;
		width: 100%;
		justify-content: space-between;
		& .rc-pagination-prev,
		& .rc-pagination-next {
			display: none;
		}
		&:before {
			content: '';
			position: absolute;
			top: calc(50% - 2px);
			left: 15px;
			height: 4px;
			width: calc(100% - 30px);
			z-index: -1;
			background: #c4c6d3;
		}
	}
`;
const Item = styled.div`
	width: 32px;
	height: 32px;
	cursor: pointer;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	& .current {
		background: var(--blue);
		border: 2px solid var(--blue);
		color: var(--white);
	}
	& .correct {
		background: #4ae323;
		color: var(--white);
		border: 2px solid #4ae323;
	}
	& .un-correct {
		background: #e73b36;
		border: 2px solid #e73b36;
		color: var(--white);
	}
	& .un-mark {
		background: var(--white);
		border: 2px solid #4ae323;
		color: #4ae323;
	}
	& div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 8px;
	}
`;

const PageItem = (current, type, element, problems, activeCurrent) => {
	const problemItem = lodash.find(
		problems,
		(item, index) => index === current - 1,
	);
	const problemName = lodash.get(problemItem, 'pivot.problem_name');
	const accepted = lodash.get(problemItem, 'submit_accepted.id');
	const status =
		typeof accepted && accepted
			? 'correct'
			: lodash.get(problemItem, 'submit_wrong.id')
			? 'un-correct'
			: 'un-mark';
	if (type === 'prev' || type === 'next') {
		return null;
	}
	return (
		<Item className="pagination-item">
			<div
				className={`f16 f600 ${
					activeCurrent === current ? 'current' : status
				}`}>
				{typeof problemName === 'string' ? problemName.toUpperCase() : null}
			</div>
		</Item>
	);
};

const Pagination = ({ current, onChange, total, problems }) => {
	const activeCurrent = current;
	return (
		<Container>
			<RCPagination
				total={total}
				pageSize={1}
				showPrevNextJumpers={false}
				showQuickJumper={false}
				current={current}
				onChange={onChange}
				itemRender={(current, type, element) =>
					PageItem(current, type, element, problems, activeCurrent)
				}
			/>
		</Container>
	);
};
export default Pagination;
