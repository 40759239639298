import { Link } from 'react-router-dom';
import styled from 'styled-components';

const A = {};
A.SignIn = styled(Link)`
	padding: 12px 18px;
	border-radius: 8px;
	white-space: nowrap;
`;
const { SignIn } = A;
export default SignIn;
