import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Arrow from '../images/arrow-right.svg';

const Container = styled.h3`
	min-height: 16px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	word-break: break-all;
	& .piece {
		margin: 0 13px;
	}
	& .home {
		margin: 0 13px 0 0;
	}
	& img {
		height: 10px;
		width: 6px;
	}
`;

const BreadCrumbs = ({ routes }) => {
	const { t } = useTranslation();
	return (
		<Container className="padding-0 margin-0 f500 f15">
			<Link className="home f-bold" to="/">
				{t('app.home')}
			</Link>
			{routes.map(({ name, path }, index) => {
				return (
					<span key={index}>
						<img src={Arrow} />
						{routes.length === index + 1 ? (
							<span className="f-grey piece">{name}</span>
						) : (
							<Link className="f-bold piece" to={path}>
								{name}
							</Link>
						)}
					</span>
				);
			})}
		</Container>
	);
};
export default BreadCrumbs;
