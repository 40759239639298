import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import RCPagination from './rc-pagination';

const Container = styled.div`
	padding: 20px 0 70px 0;
	& .info-problem {
		border: 1px solid var(--light-grey);
		padding: 15px;
		border-radius: 16px;
	}
`;
const Table = styled.div`
	overflow-x: scroll;
	margin: 20px 0;
	&::-webkit-scrollbar {
		height: 4px;
	}
	& table {
		border-collapse: collapse;
		border-spacing: 0;
		min-width: 100%;
		& td,
		& th {
			height: 50px;
			font-size: 20px;
			font-weight: 500;
			white-space: nowrap;
			padding: 10px 20px;
		}
	}
`;
const Ratings = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 15px 0;
	& a {
		width: 120px;
		height: 40px;
		border: 1px solid var(--grey);
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;
const Title = styled.h3`
	font-size: 32px;
	@media (max-width: 768px) {
		font-size: 24px;
	}
`;

const ProblemInfo = ({ problems, problem }) => {
	const { id, slug } = useParams();
	const history = useHistory();
	const { t } = useTranslation();
	const [total, setTotal] = useState(1);
	const {
		body,
		input_type,
		memory_limit,
		output_type,
		pcode,
		status,
		time_limit,
		title,
		type_id,
		test_case,
	} = problem;
	const current = problems.findIndex(item => item.id === parseInt(id)) + 1;
	return (
		<Container>
			<RCPagination
				total={problems.length}
				current={current ? current : null}
				problems={problems}
				onChange={page =>
					history.push(
						`/online-contests/${slug}/problems/${problems[page - 1].id}`,
					)
				}
			/>
			<Ratings>
				<Title>{title}</Title>
				<Link
					className="f18 f600 f-grey"
					to={`/online-contests/${slug}/results`}>
					{t('online-contest-start.rating')}
				</Link>
			</Ratings>
			<p
				className="f-grey f20 f500"
				dangerouslySetInnerHTML={{ __html: body }}></p>
			<h3 className="f-blue f18 f500">
				{t('online-contest-start.input-data')}
			</h3>
			<p
				className="f-grey f20 f500"
				dangerouslySetInnerHTML={{ __html: input_type }}></p>
			<h3 className="f-blue f18 f500">
				{t('online-contest-start.output-data')}
			</h3>
			<p
				className="f-grey f20 f500"
				dangerouslySetInnerHTML={{ __html: output_type }}></p>
			{test_case.length ? (
				<>
					<h3 className="f-blue f18 f500">
						{t('online-contest-start.problems')}
					</h3>
					<Table>
						<table>
							<thead>
								<tr className="bg-light">
									<th style={{ border: '1px solid var(--light-grey)' }}>#</th>
									<th
										style={{ border: '1px solid var(--light-grey)' }}
										className="f20 f500 f-left">
										{t('online-contest-start.input-info')}
									</th>
									<th
										style={{ border: '1px solid var(--light-grey)' }}
										className="f20 f500 f-left">
										{t('online-contest-start.output-info')}
									</th>
									<th
										style={{ border: '1px solid var(--light-grey)' }}
										className="f20 f500 f-left">
										{t('online-contest-start.description')}
									</th>
								</tr>
							</thead>
							<tbody>
								{test_case.map(
									({ sample_input, sample_output, sample_note }, index) => {
										return (
											<tr key={index} className="bg-white">
												<td
													style={{ border: '1px solid var(--light-grey)' }}
													className="f-center">
													{index + 1}
												</td>
												<td
													style={{ border: '1px solid var(--light-grey)' }}
													className="f20 f500"
													dangerouslySetInnerHTML={{
														__html: sample_input,
													}}></td>
												<td
													style={{ border: '1px solid var(--light-grey)' }}
													dangerouslySetInnerHTML={{ __html: sample_output }}
													className="f20 f500"></td>
												<td
													style={{ border: '1px solid var(--light-grey)' }}
													dangerouslySetInnerHTML={{ __html: sample_note }}
													className="f20 f500"></td>
											</tr>
										);
									},
								)}
							</tbody>
						</table>
					</Table>
				</>
			) : null}
		</Container>
	);
};
export default ProblemInfo;
