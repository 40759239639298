import axios from 'axios';
import { getLocalStorage } from './local-storage';

export const Request = () => {
	// return - object
	const { REACT_APP_API_URL: API_URL } = process.env;
	const { token, i18nextLng: lang } = getLocalStorage(['token', 'i18nextLng']);
	const defaultOptions = {
		baseURL: API_URL,
		headers: {
			Authorization: token ? `Bearer ${token}` : '',
			lang: lang || 'uz',
		},
	};
	return {
		get: (url, options = {}) =>
			axios.get(url, { ...defaultOptions, ...options }),
		post: (url, data, options = {}) =>
			axios.post(url, data, { ...defaultOptions, ...options }),
		put: (url, data, options = {}) =>
			axios.put(url, data, { ...defaultOptions, ...options }),
		delete: (url, options = {}) =>
			axios.delete(url, { ...defaultOptions, ...options }),
	};
};
