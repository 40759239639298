import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ArrowDown from '../images/arrow-down.svg';
import RussianFlag from '../images/russian-flag.svg';
import UzbekistanFlag from '../images/uzbekistan-flag.svg';
import { useDetect } from '../services';

const Container = styled.div`
	margin: 0 15px 0 0;
`;
const MenuButton = styled.button`
	vertical-align: middle;
	transition: box-shadow 0.4s ease;
	margin-left: auto;
	display: flex;
	align-items: center;
	& .menu-name {
		@media (max-width: 576px) {
			display: none;
		}
	}
	& .down {
		height: 7px;
		width: 10px;
		margin: 0 0 0 7px;
		@media (max-width: 576px) {
			display: none;
		}
	}
`;
const Menu = styled.ul`
	border-radius: 8px;
	top: 53px;
	right: 0;
	box-shadow: 0 1px 8px rgba(110, 120, 146, 0.5);
	opacity: ${p => (p.isActive ? 1 : 0)};
	visibility: ${p => (p.isActive ? 'visible' : 'hidden')};
	transform: translateY(${p => (p.isActive ? '0' : '-20px')});
	@media (max-width: 768px) {
		top: 43px;
	}
`;
const Image = styled.img`
	margin: 0 7px -3px 0;
	width: 18px;
	height: 18px;
`;
const Flag = styled.img`
	margin: 0 7px 0 0;
	width: 18px;
	height: 18px;
	display: ${p => p.isActive};
`;
const Option = styled.li`
	white-space: nowrap;
	padding: 13px 18px;
	cursor: pointer;
	border-bottom: 1px solid rgba(110, 120, 146, 0.15);
	background: ${p => (p.active ? 'rgba(110, 120, 146, 0.15)' : 'transparent')};
	&:hover {
		background: rgba(110, 120, 146, 0.15);
	}
	& p {
		height: 100%;
		width: 100%;
		margin: 0;
	}
`;

const Language = () => {
	const ref = useRef(null);
	const { REACT_APP_IMAGE_ALT: ImageAlt } = process.env;
	const { i18n } = useTranslation();
	const { language } = i18n;
	const [active, setActive] = useDetect(ref, false);
	const onClick = () => setActive(!active);
	const changeLanguage = (e, language) => {
		e.stopPropagation();
		i18n.changeLanguage(language);
		setActive(!active);
	};
	return (
		<Container className="p-relative">
			<MenuButton
				onClick={onClick}
				className="bg-0 padding-0 f700 f15 border-0">
				<Flag
					alt={ImageAlt}
					src={UzbekistanFlag}
					isActive={language === 'uz' ? 'inline-block' : 'none'}
				/>
				<Flag
					alt={ImageAlt}
					src={RussianFlag}
					isActive={language === 'ru' ? 'inline-block' : 'none'}
				/>
				<span className="menu-name">
					{language === 'ru' ? 'Русский' : "O'zbek tili"}
				</span>
				<img alt={ImageAlt} className="down" src={ArrowDown} />
			</MenuButton>
			<Menu
				ref={ref}
				isActive={active}
				className="p-absolute trans-5 margin-0 overflow-0 bg-white">
				<Option
					className="f15 f600 trans-5"
					active={language === 'uz' ? true : false}
					onClick={e => changeLanguage(e, 'uz')}>
					<p>
						<Image alt={ImageAlt} src={UzbekistanFlag} />
						<span>O'zbek tili</span>
					</p>
				</Option>
				<Option
					className="f15 f600 trans-5"
					active={language === 'ru' ? true : false}
					onClick={e => changeLanguage(e, 'ru')}>
					<p>
						<Image alt={ImageAlt} src={RussianFlag} />
						<span>Русский</span>
					</p>
				</Option>
			</Menu>
		</Container>
	);
};
export default Language;
