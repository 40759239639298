import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LightLogo from '../images/header-light-logo.svg';

const Container = styled.div`
	padding: calc(4vw + 30px) 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	& img {
		height: 58px;
	}
	& .copyright {
		margin: 40px 0 0 0;
	}
	& .phone {
		font-size: 24px;
		color: var(--white);
		transition: 300ms;
		&:hover {
			color: var(--yellow);
		}
	}
`;

const Footer = () => {
	const { REACT_APP_IMAGE_ALT: ImageAlt } = process.env;
	const { t } = useTranslation();
	return (
		<section className="container bg-bold">
			<Container>
				<Link to="/">
					<img src={LightLogo} alt={ImageAlt} />
				</Link>
				<p className="f500 f-white f18">{t('footer.phone')}</p>
				<p className="margin-0">
					<a className="phone" href="tel:+998971119810">
						+998 (97) 111 98 10
					</a>
				</p>
				<p className="f500 f18">
					<a className="f-white" target="_blank" href="https://t.me/sh_turonov">
						{t('footer.telegram-admin')}
					</a>
				</p>
				<div className="copyright">
					<p className="margin-0 f500 f20 f-white f-center">
						{t('footer.copyright')}
					</p>
				</div>
			</Container>
		</section>
	);
};
export default Footer;
