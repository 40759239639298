import { lazy } from 'react';

const OnlineContestStart = lazy(() => import('./online-contest-start.jsx'));
const Contest = lazy(() => import('./contest.jsx'));
const ContestStart = lazy(() => import('./contest-start.jsx'));
const Result = lazy(() => import('./result.jsx'));
const Profile = lazy(() => import('./profile.jsx'));
const SignIn = lazy(() => import('./sign-in.jsx'));
const SignUp = lazy(() => import('./sign-up.jsx'));
const ForgotPassword = lazy(() => import('./forgot-password.jsx'));
const Home = lazy(() => import('./home.jsx'));
const NotFound = lazy(() => import('./not-found.jsx'));
const OnlineContests = lazy(() => import('./online-contests.jsx'));
const OnlineContest = lazy(() => import('./online-contest.jsx'));
const OnlineContestResults = lazy(() => import('./online-contest-results.jsx'));
const OnlineContestNextLevel = lazy(() =>
	import('./online-contest-next-level.jsx'),
);
const OnlineContestWinners = lazy(() => import('./online-content-winners.jsx'));

const ROUTES = [
	{ component: SignIn, path: '/sign-in', exact: true, type: 'authentication' },
	{ component: SignUp, path: '/sign-up', exact: true, type: 'authentication' },
	{
		component: ForgotPassword,
		path: '/forgot-password',
		exact: true,
		type: 'authentication',
	},
	{ component: Profile, path: '/profile', exact: true, type: 'profile' },
	{
		component: Contest,
		path: '/contest/:id',
		exact: true,
		type: 'check-profile',
	},
	{
		component: ContestStart,
		path: '/contest/:id/start',
		exact: true,
		type: 'check-profile',
	},
	{
		component: Result,
		path: '/contest/:id/result',
		exact: true,
		type: 'check-profile',
	},
	{
		component: OnlineContests,
		path: '/online-contests',
		exact: true,
		type: 'check-profile',
	},
	{
		component: OnlineContest,
		path: '/online-contests/:slug',
		exact: true,
		type: 'check-profile',
	},
	{
		component: OnlineContestStart,
		path: '/online-contests/:slug/problems/:id',
		exact: true,
		type: 'check-profile',
	},
	{
		component: OnlineContestResults,
		path: '/online-contests/:slug/results',
		exact: true,
		type: 'check-profile',
	},
	{
		component: OnlineContestNextLevel,
		path: '/online-contests/:slug/next-level',
		exact: true,
		type: 'check-profile',
	},
	{
		component: OnlineContestWinners,
		path: '/online-contest-winners',
		exact: true,
		type: 'profile',
	},
	{ component: Home, path: '/', exact: true, type: 'default' },
	{ component: NotFound, path: '*', exact: true, type: 'default' },
];
export default ROUTES;
