import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Show from '../images/hide-password.svg';
import Hide from '../images/show-password.svg';

const Container = styled.div`
	width: 100%;
	border-radius: 8px;
	display: flex;
	align-items: center;
	margin: 0 0 32px 0;
	& input {
		width: calc(100% - 48px);
		height: 60px;
		border-radius: 8px;
		padding: 0 0 0 24px;
		letter-spacing: ${p => (p.type === 'password' ? '3px' : '0')};
		&::placeholder {
			letter-spacing: 0;
		}
	}
	& div {
		border-radius: 9px;
		height: 48px;
		width: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		& img {
			height: 24px;
			width: 24px;
		}
		& .show-password {
			display: ${p => (p.show ? 'none' : 'inline-block')};
		}
		& .hide-password {
			display: ${p => (p.show ? 'inline-block' : 'none')};
		}
	}
`;

const PasswordInput = ({
	name,
	onChange,
	value,
	onFocus,
	placeholder,
	background,
}) => {
	const [type, setType] = useState(false);
	const ref = useRef();
	const { REACT_APP_IMAGE_ALT: ImageAlt } = process.env;
	const changeType = () => {
		setType(!type);
		ref.current.focus();
	};
	return (
		<Container
			show={type}
			type={type ? 'text' : 'password'}
			className={background ? background : 'bg-white'}>
			<input
				ref={ref}
				onFocus={onFocus}
				onChange={onChange}
				className="f600 f18 bg-0 border-0"
				value={value}
				type={type ? 'text' : 'password'}
				placeholder={placeholder}
				name={name}
			/>
			<div className="bg-0" onClick={() => changeType()}>
				<img className="show-password" src={Show} alt={ImageAlt} />
				<img className="hide-password" src={Hide} alt={ImageAlt} />
			</div>
		</Container>
	);
};
export default PasswordInput;
