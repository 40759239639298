const storage = window.localStorage;
export const getLocalStorage = keys => {
	// keys - array
	// return - object
	const parameters = keys.reduce((parameter, key) => {
		const value = storage.getItem(key);
		parameter[key] = value;
		return parameter;
	}, {});
	return parameters;
};
export const setLocalStorage = keys => {
	// keys - array
	keys.forEach(({ name, value }) => {
		storage.setItem(name, value);
	});
};
export const removeLocalStorage = keys => {
	// key - array
	keys.forEach(key => {
		storage.removeItem(key);
	});
};
export const clearLocalStorage = () => {
	storage.clear();
};
