import {
	ASSIGNMENT,
	LOADED,
	LOADING,
	LOGIN,
	LOGOUT,
	REGIONS,
	UPDATE_SCROLL,
	UPDATE_USER,
	USER_TAKEN,
} from './types';

export const loading = () => {
	return {
		type: LOADING,
	};
};
export const loaded = () => {
	return {
		type: LOADED,
	};
};
export const logout = () => {
	return {
		type: LOGOUT,
	};
};
export const login = () => {
	return {
		type: LOGIN,
	};
};
export const userTaken = () => {
	return {
		type: USER_TAKEN,
	};
};
export const updateScroll = scroll => {
	return {
		type: UPDATE_SCROLL,
		scroll,
	};
};
export const userUpdate = user => {
	return {
		type: UPDATE_USER,
		user,
	};
};
export const getRegions = regions => {
	return {
		type: REGIONS,
		regions,
	};
};
export const updateAssignment = assignments => {
	return {
		type: ASSIGNMENT,
		assignments,
	};
};
