import {
	ASSIGNMENT,
	LOADED,
	LOADING,
	LOGIN,
	LOGOUT,
	REGIONS,
	UPDATE_SCROLL,
	UPDATE_USER,
	USER_TAKEN,
} from './types';

const initialState = {
	loading: true,
	authentication: false,
	userTaken: false,
	scroll: false,
	user: {
		firstname: '',
		lastname: '',
		region_id: '',
		city_id: '',
		region: '',
		avatar: '',
		name: '',
		birth_date: '',
		profession: [],
		status: '',
	},
	regions: [],
	assignments: [],
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING:
			return {
				...state,
				loading: true,
			};
		case LOADED:
			return {
				...state,
				loading: false,
			};
		case USER_TAKEN:
			return {
				...state,
				userTaken: true,
			};
		case LOGOUT:
			return {
				...state,
				authentication: false,
			};
		case LOGIN:
			return {
				...state,
				authentication: true,
			};
		case UPDATE_SCROLL:
			return {
				...state,
				scroll: action.scroll,
			};
		case UPDATE_USER:
			return {
				...state,
				user: action.user,
			};
		case REGIONS:
			return {
				...state,
				regions: action.regions,
			};
		case ASSIGNMENT:
			return {
				...state,
				assignments: action.assignments,
			};
		default:
			return state;
	}
};
export default reducer;
