import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';
import Controller from './controller.jsx';
import { Loading, Notification, Progress, ScrollTop } from './layout';
import ROUTES from './pages';
import {
	getRegions,
	loaded,
	login,
	updateAssignment,
	updateScroll,
	userTaken,
	userUpdate,
} from './redux';
import { getLocalStorage, removeLocalStorage, Request } from './services';

const App = () => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const { language } = i18n;
	const { token } = getLocalStorage(['token']);
	const {
		loading,
		authentication,
		userTaken: isFetched,
		user,
	} = useSelector(state => state);
	useEffect(() => {
		if (token) {
			Request()
				.get('/profil/get')
				.then(async response => {
					const { data, success } = response.data;
					if (success === 1) {
						await dispatch(login());
						await dispatch(userUpdate(data));
						dispatch(userTaken());
					} else {
						removeLocalStorage(['token']);
					}
				})
				.catch(() => {
					dispatch(userTaken());
					removeLocalStorage(['token']);
				});
		} else {
			dispatch(userTaken());
			removeLocalStorage(['token']);
		}
		dispatch(loaded());
	}, []);
	useEffect(() => {
		Request()
			.get('/assignment')
			.then(response => {
				const { status, data } = response.data;
				if (status === 1 && Array.isArray(data)) {
					dispatch(updateAssignment(data));
				}
			})
			.catch(e => console.log(e));
	}, [authentication, user]);
	useEffect(() => {
		if (isFetched && authentication) {
			Request()
				.get('/profil/regions')
				.then(response => {
					const { status, data } = response.data;
					if (status === 1) {
						if (Array.isArray(data)) {
							const regions = data.map(({ name, id }) => {
								return { label: name, value: id, isDisabled: false };
							});
							dispatch(getRegions(regions));
						}
					}
				})
				.catch(e => console.log(e));
		}
	}, [authentication, isFetched, language]);
	useEffect(() => {
		let position = window.pageYOffset;
		window.addEventListener('scroll', () => {
			const currentPosition = window.pageYOffset;
			if (position < currentPosition) {
				dispatch(updateScroll(true));
			} else {
				dispatch(updateScroll(false));
			}
			position = currentPosition;
		});
		window.addEventListener('offline', () => toast.warning(t('app.offline')));
		window.addEventListener('online', () => toast.info(t('app.online')));
	}, []);
	return (
		<Router>
			<ScrollTop>
				<Suspense fallback={<Progress />}>
					<Switch>
						{ROUTES.map((route, index) => (
							<Controller key={index} {...route} />
						))}
					</Switch>
				</Suspense>
			</ScrollTop>
			<Notification />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				// hideProgressBar={false}
				// newestOnTop={false}
				closeOnClick
				// rtl={false}
				pauseOnFocusLoss
				draggable
				transition={Slide}
				pauseOnHover
			/>
			{loading ? <Loading /> : null}
		</Router>
	);
};
export default App;
