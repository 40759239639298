import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Time = styled.div`
	display: flex;
	justify-content: center;
	font-size: 88px;
	@media (max-width: 991px) {
		font-size: 56px;
	}
	@media (max-width: 768px) {
		font-size: 36px;
	}
	& .item {
		margin: 10px 10px;
		& .item-title {
			font-size: 24px;
			@media (max-width: 768px) {
				font-size: 18px;
			}
		}
	}
`;

const Timer = ({ difference, setState }) => {
	const { t } = useTranslation();
	const [time, setTime] = useState(difference);
	const day = parseInt(time / (1000 * 60 * 60 * 24));
	const dayRemain = time % (1000 * 60 * 60 * 24);
	const hour = parseInt(dayRemain / (1000 * 60 * 60));
	const hourRemain = parseInt(dayRemain % (1000 * 60 * 60));
	const minute = parseInt(hourRemain / (1000 * 60));
	const minuteRemain = parseInt(hourRemain % (1000 * 60));
	const second = parseInt(minuteRemain / 1000);
	useEffect(() => {
		const tick = () => {
			if (time > 0) {
				setTime(time - 1000);
			} else {
				setState(2);
			}
		};
		const timer = setInterval(_ => tick(), 1000);
		return () => clearInterval(timer);
	}, [time]);
	return (
		<Time>
			<div className="item">
				<div className="f700 number f-blue">
					{day.toString().padStart(2, '0')}
				</div>
				<div className="f700 item-title f-center f-bold">{t('app.day')}</div>
			</div>
			<div className="f700 number item f-blue">:</div>
			<div className="item">
				<div className="f700 number f-blue">
					{hour.toString().padStart(2, '0')}
				</div>
				<div className="f700 item-title f-center f-bold">{t('app.hour')}</div>
			</div>
			<div className="f700 number item f-blue">:</div>
			<div className="item">
				<div className="f700 number f-blue">
					{minute.toString().padStart(2, '0')}
				</div>
				<div className="f700 item-title f-center f-bold">{t('app.minute')}</div>
			</div>
			<div className="f700 number item f-blue">:</div>
			<div className="item">
				<div className="f700 number f-blue">
					{second.toString().padStart(2, '0')}
				</div>
				<div className="f700 item-title f-center f-bold">{t('app.second')}</div>
			</div>
		</Time>
	);
};
export default Timer;
