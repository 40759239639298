import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Check from '../images/check.svg';
import PaperClip from '../images/paper-clip.svg';
import { loaded, loading } from '../redux';
import { Request } from '../services';

const Container = styled.form`
	padding: 16px;
	border: 1px solid var(--light-grey);
	border-radius: 16px;
	margin: 20px 0;
`;
const File = styled.a`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	height: 50px;
	padding: 0 16px;
`;
const Label = styled.label`
	height: 50px;
	border-radius: 8px;
	padding: 0 20px;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	& img {
		height: 22px;
		margin: 0 5px 0 0;
	}
	& input {
		display: none;
	}
	@media (max-width: 576px) {
		width: 100%;
		justify-content: center;
	}
`;
const Button = styled.button`
	margin: 20px 0;
	border-radius: 8px;
	height: 50px;
	width: 200px;
	@media (max-width: 576px) {
		width: 100%;
	}
`;
const Textarea = styled.textarea`
	width: 450px;
	height: 200px;
	border: 1px solid var(--grey);
	border-radius: 8px;
	padding: 5px 5px 5px 16px;
	&:hover,
	&:focus {
		border: 1px solid var(--blue);
	}
	@media (max-width: 576px) {
		width: 100%;
	}
`;

const Form = ({ task: { name, desc, file, id, passing }, index }) => {
	const { t } = useTranslation();
	const files = JSON.parse(file);
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const [error, setError] = useState(false);
	const [fileError, setFileError] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [passings, setPassings] = useState({
		file_name: '',
		file: '',
	});
	const [comment, setComment] = useState('');
	const { REACT_APP_IMAGE_ALT: ImageAlt, REACT_APP_MEDIA_URL: MediaUrl } =
		process.env;
	const submit = async e => {
		e.preventDefault();
		const files = inputRef.current.files;
		if (files.length && files[0].size / (1024 * 1024) < 200) {
			await dispatch(loading());
			const formData = new FormData();
			formData.append('task_id', id);
			formData.append('file', files[0]);
			if (comment) {
				formData.append('details', comment);
			}
			Request()
				.post('/task/save', formData)
				.then(response => {
					const { status, msg, data } = response.data;
					if (status === 1) {
						if (msg === 'contest close') {
							toast.warning(t('app.contest-close'));
						} else {
							const { file, file_name } = data;
							setPassings({ file, file_name });
							toast.success(t('app.success'));
							setIsSubmitted(true);
						}
					} else {
						toast.warning(t('app.error'));
					}
				})
				.catch(() => toast.warning(t('app.error')))
				.finally(() => dispatch(loaded()));
		} else if (!files.length) {
			setError(true);
		} else if (files[0].size / (1024 * 1024) > 199) {
			setFileError(true);
		}
	};
	useEffect(() => {
		if (passing) {
			const { details } = passing;
			setComment(details);
			setPassings(passing);
			if (passing.file) {
				setIsSubmitted(true);
			}
		}
	}, [passing]);
	return (
		<Container onSubmit={submit}>
			<h4 className="f24 f600" style={{ lineHeight: '150%' }}>
				{index + 1}. {name}
			</h4>
			<p className="f20 f500" dangerouslySetInnerHTML={{ __html: desc }}></p>
			{files.length ? (
				<>
					<p className="f18 f700" style={{ lineHeight: '150%' }}>
						{t('app.files')}
					</p>
					<p>
						{files.map(({ download_link, original_name }, index) => (
							<File
								key={index}
								href={MediaUrl + '/' + download_link}
								className="bg-blue f-white f16 f600"
								target="_blank"
								download>
								{original_name}
							</File>
						))}
					</p>
				</>
			) : null}
			{passings.file ? (
				<>
					<p className="f18 f700" style={{ lineHeight: '150%' }}>
						{t('app.sended-file')}
					</p>
					<p>
						<File
							href={MediaUrl + '/' + passings.file}
							className="bg-blue f-white f16 f600"
							target="_blank"
							download>
							{passings.file_name}
						</File>
					</p>
				</>
			) : null}
			<h4 className="f24 f700">
				<span>{t('app.write-answer')} </span>
				{isSubmitted ? (
					<>
						<img src={Check} style={{ margin: '0 10px' }} alt={ImageAlt} />
						<span className="f24 f700 f-blue">{t('app.submitted')}</span>
					</>
				) : null}
			</h4>
			<p className="f700 f18" style={{ lineHeight: '150%' }}>
				{t('app.upload-file')}
			</p>
			<p>
				<Label
					onClick={() => {
						setError(false);
						setFileError(false);
					}}
					className="bg-grey f-white">
					<img src={PaperClip} alt={ImageAlt} />
					<input ref={inputRef} accept=".zip,.rar,.7zip" type="file" />
					<span className="f700 f16">{t('app.upload')}</span>
				</Label>
			</p>
			{error ? <p className="f700 f-red f18">{t('app.no-entered')}</p> : null}
			{fileError ? (
				<p className="f700 f-red f18">{t('app.file-error')}</p>
			) : null}
			<Textarea
				className="f18 f600"
				value={comment}
				onChange={e => setComment(e.target.value)}
				placeholder={t('app.comment')}></Textarea>
			<div>
				<Button type="submit" className="border-0 bg-blue f-white f18 f700">
					{t('app.save')}
				</Button>
			</div>
		</Container>
	);
};
export default Form;
