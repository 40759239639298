import styled from 'styled-components';

const Bottom = styled.section`
	width: 100%;
	height: 88px;
	@media (max-width: 768px) {
		height: 65px;
	}
`;
export default Bottom;
