import { ru, uz } from 'date-fns/locale';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDetect } from '../services';

const Container = styled.div`
	width: 100%;
	height: 60px;
	margin: 16px 0 32px 0;
	position: relative;
	& .placeholder {
		position: absolute;
		left: 20px;
		top: -10px;
		background: var(--white);
	}
`;
const Button = styled.button`
	height: 100%;
	border-radius: 8px;
	width: 100%;
	display: flex;
	align-items: center;
	border: 1px solid rgba(137, 141, 166, 0.4);
	&:hover,
	&:focus {
		border: 1px solid var(--blue);
	}
	& .down {
		display: inline-block;
		width: 30px;
		height: 9px;
		&:before {
			position: absolute;
			top: -2px;
			left: 2px;
			content: '';
			width: 7px;
			height: 7px;
			border-width: 0 0 2px 2px;
			border-style: solid;
			transform: rotate(-45deg);
			border-color: var(--grey);
		}
	}
	& .selected-day {
		width: calc(100% - 30px);
		padding: 0 0 0 20px;
	}
`;
const DatePickerHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	& .header-button {
		height: 35px;
		width: 35px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		& svg {
			width: 10px;
			fill: var(--bold);
		}
		&:hover {
			background: var(--blue);
			& svg {
				fill: var(--white);
			}
		}
	}
	& select {
		cursor: pointer;
		& option {
			font-weight: 600;
		}
	}
`;
const Card = styled.div`
	left: 0;
	top: 60px;
	display: inline-block;
	border-radius: 12px;
	padding: 10px;
	box-shadow: 0 1px 12px rgba(110, 120, 146, 0.5);
	opacity: ${p => (p.active ? 1 : 0)};
	visibility: ${p => (p.active ? 'visible' : 'hidden')};
	transform: translateY(${p => (p.active ? '0' : '-20px')});
	transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
	z-index: 5;
	& .react-datepicker {
		border: 0;
		background: transparent;
		font-family: 'Gilroy', sans-serif;
		font-size: 16px;
	}
	& .react-datepicker__navigation {
		top: 0.075rem;
		opacity: 0.5;
	}
	& .react-datepicker__header {
		padding: 0;
		border: 0;
		background: transparent;
	}
	& .react-datepicker__current-month {
		display: flex;
		font-size: 17px;
		align-items: center;
		justify-content: center;
		height: 2.5rem;
		text-transform: capitalize;
	}
	& .react-datepicker__day {
		outline: none;
		border-radius: 50%;
		height: 35px;
		width: 35px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: 0.1s;
	}
	& .react-datepicker__day-names {
		background: var(--light-grey);
		border-radius: 8px;
		padding: 5px;
	}
	& .react-datepicker__week {
		padding: 2px 5px;
	}
	& .react-datepicker__day-name {
		height: 35px;
		width: 35px;
		cursor: default;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		text-transform: capitalize;
	}
	& .react-datepicker,
	& .react-datepicker__header,
	& .react-datepicker__day--outside-month,
	& .react-datepicker__current-month,
	& .react-datepicker__day,
	& .react-datepicker__day-name {
		color: var(--bold);
		font-weight: 600;
	}
	& .react-datepicker__day--selected,
	& .react-datepicker__day--keyboard-selected,
	& .react-datepicker__day:hover {
		background: var(--blue);
		color: var(--white);
	}
`;

const DateInput = ({ date, onChange }) => {
	const {
		i18n: { language },
		t,
	} = useTranslation();
	const ref = useRef(null);
	const [active, setActive] = useDetect(ref, false);
	const [day, setDay] = useState('');
	const onClick = () => setActive(!active);
	const [years, setYears] = useState([]);
	const months = {
		ru: [
			'Январь',
			'Февраль',
			'Март',
			'Апреля',
			'Май',
			'Июнь',
			'Июль',
			'Август',
			'Сентябрь',
			'Октябрь',
			'Ноябрь',
			'Декабрь',
		],
		uz: [
			'Yanvar',
			'Fevral',
			'Mart',
			'Aprel',
			'May',
			'Iyun',
			'Iyul',
			'Avgust',
			'Sentyabr',
			'Oktyabr',
			'Noyabr',
			'Dekabr',
		],
	};
	const changeDay = date => {
		onChange(date);
		setActive(false);
	};
	useEffect(() => {
		const day = `${date.getDate()} ${
			months[language][date.getMonth()]
		} ${date.getFullYear()} ${language === 'uz' ? 'yil' : 'года'}`;
		setDay(day);
	}, [date]);
	useEffect(() => {
		const lang = language === 'ru' ? ru : uz;
		registerLocale(language, lang);
	}, [language]);
	useEffect(() => {
		const years = [];
		for (let x = 1; x < 42; x++) {
			years.push(x + 1969);
		}
		setYears(years);
	}, []);
	return (
		<Container className="p-relative">
			<div className="placeholder f700">{t('app.birth-date')}</div>
			<Button
				type="button"
				className="border-0 trans-5 outline-0 bg-0 padding-0 f700 f18"
				onClick={onClick}>
				<span className="selected-day f-left">{day}</span>
				<span className="down p-relative"></span>
			</Button>
			<Card
				onClick={e => e.stopPropagation()}
				className="p-absolute bg-white"
				ref={ref}
				active={active}>
				<DatePicker
					inline
					maxDate={new Date('2010-01-01')}
					minDate={new Date('1970-01-01')}
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					locale={language === 'uz' ? uz : ru}
					selected={date}
					renderCustomHeader={({
						date,
						prevMonthButtonDisabled,
						nextMonthButtonDisabled,
						changeYear,
						changeMonth,
						decreaseMonth,
						increaseMonth,
						getFullYear,
					}) => (
						<DatePickerHeader>
							<button
								type="button"
								onClick={decreaseMonth}
								className="header-button bg-0 border-0"
								disabled={prevMonthButtonDisabled}>
								<svg
									viewBox="0 0 6 8"
									style={{ transform: 'rotate(180deg)' }}
									fill="none">
									<path d="M4.88712 3.5268L2.06045 0.700129C1.99847 0.637643 1.92474 0.588047 1.8435 0.554201C1.76226 0.520355 1.67512 0.50293 1.58712 0.50293C1.49911 0.50293 1.41197 0.520355 1.33073 0.554201C1.24949 0.588047 1.17576 0.637643 1.11378 0.700129C0.989616 0.825037 0.919922 0.994005 0.919922 1.17013C0.919922 1.34625 0.989616 1.51522 1.11378 1.64013L3.47378 4.00013L1.11378 6.36013C0.989616 6.48504 0.919922 6.65401 0.919922 6.83013C0.919922 7.00625 0.989616 7.17522 1.11378 7.30013C1.17608 7.36192 1.24995 7.4108 1.33118 7.44398C1.4124 7.47715 1.49938 7.49397 1.58712 7.49346C1.67485 7.49397 1.76183 7.47715 1.84305 7.44398C1.92428 7.4108 1.99816 7.36192 2.06045 7.30013L4.88712 4.47346C4.9496 4.41149 4.9992 4.33775 5.03304 4.25651C5.06689 4.17527 5.08431 4.08814 5.08431 4.00013C5.08431 3.91212 5.06689 3.82498 5.03304 3.74374C4.9992 3.6625 4.9496 3.58877 4.88712 3.5268Z" />
								</svg>
							</button>
							<div>
								<select
									className="border-0 outline-0 f17 f600"
									value={months[language][date.getMonth()]}
									onChange={({ target: { value } }) =>
										changeMonth(months[language].indexOf(value))
									}>
									{months[language].map(option => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</select>
								<select
									className="border-0 outline-0 f17 f600"
									value={date.getFullYear()}
									onChange={({ target: { value } }) => changeYear(value)}>
									{years.map(option => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</select>
							</div>
							<button
								type="button"
								onClick={increaseMonth}
								className="header-button bg-0 border-0"
								disabled={nextMonthButtonDisabled}>
								<svg viewBox="0 0 6 8" fill="none">
									<path d="M4.88712 3.5268L2.06045 0.700129C1.99847 0.637643 1.92474 0.588047 1.8435 0.554201C1.76226 0.520355 1.67512 0.50293 1.58712 0.50293C1.49911 0.50293 1.41197 0.520355 1.33073 0.554201C1.24949 0.588047 1.17576 0.637643 1.11378 0.700129C0.989616 0.825037 0.919922 0.994005 0.919922 1.17013C0.919922 1.34625 0.989616 1.51522 1.11378 1.64013L3.47378 4.00013L1.11378 6.36013C0.989616 6.48504 0.919922 6.65401 0.919922 6.83013C0.919922 7.00625 0.989616 7.17522 1.11378 7.30013C1.17608 7.36192 1.24995 7.4108 1.33118 7.44398C1.4124 7.47715 1.49938 7.49397 1.58712 7.49346C1.67485 7.49397 1.76183 7.47715 1.84305 7.44398C1.92428 7.4108 1.99816 7.36192 2.06045 7.30013L4.88712 4.47346C4.9496 4.41149 4.9992 4.33775 5.03304 4.25651C5.06689 4.17527 5.08431 4.08814 5.08431 4.00013C5.08431 3.91212 5.06689 3.82498 5.03304 3.74374C4.9992 3.6625 4.9496 3.58877 4.88712 3.5268Z" />
								</svg>
							</button>
						</DatePickerHeader>
					)}
					onChange={changeDay}
				/>
			</Card>
		</Container>
	);
};
export default DateInput;
