import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ContestMenu, Language, User } from '../components';
import DarkLogo from '../images/header-dark-logo.svg';
import LogoSmall from '../images/header-small-logo.svg';
import {
	Bottom,
	Head,
	Logo,
	Section,
	SignIn,
	SmallLogo,
} from '../styled-components/header';

const Header = () => {
	const { REACT_APP_IMAGE_ALT: ImageAlt } = process.env;
	const { authentication, scroll } = useSelector(state => state);
	const { t, i18n } = useTranslation();
	const { language } = i18n;
	useEffect(() => {
		window.document.title = t('app.title');
	}, [language]);
	return (
		<>
			<Head scroll={scroll} className="container">
				<Section>
					<Link to="/">
						<Logo alt={ImageAlt} src={DarkLogo} />
						<SmallLogo alt={ImageAlt} src={LogoSmall} />
					</Link>
					{authentication ? <ContestMenu /> : null}
				</Section>
				<Section>
					<Language />
					{authentication ? (
						<User />
					) : (
						<SignIn to="/sign-in" className="bg-bold f700 f-white f15">
							{t('header.sign-in')}
						</SignIn>
					)}
				</Section>
			</Head>
			<Bottom />
		</>
	);
};
export default Header;
