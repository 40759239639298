import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
   100% {
      transform: rotate(360deg);
   }
`;
const dash = keyframes`
   0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
   }
   50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
   }
   100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
   }
`;

const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	& svg {
		animation: ${rotate} 2s linear infinite;
		& circle {
			animation: ${dash} 1.75s ease-in-out infinite;
		}
	}
	@media (max-width: 768px) {
		height: calc(100vh - 65px);
	}
`;
const Suspense = () => (
	<Container>
		<svg height="70" width="70" viewBox="0 0 50 50">
			<circle
				cx="25"
				cy="25"
				fill="none"
				stroke="#5254f1"
				strokeWidth="4"
				strokeLinecap="round"
				r="20"></circle>
		</svg>
	</Container>
);
export default Suspense;
