// import { applyMiddleware, createStore } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from 'redux-logger';
// import reducer from './store/reducer';
// const store = createStore(
// 	reducer,
// 	composeWithDevTools(applyMiddleware(logger)),
// );

import { createStore } from 'redux';
import reducer from './store/reducer';
const store = createStore(reducer);

export default store;
