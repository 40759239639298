import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ArrowDown from '../images/arrow-down.svg';
import { useDetect } from '../services';

const Container = styled.div`
	display: flex;
	justify-content: center;
	cursor: pointer;
	height: 43px;
	margin: 0 0 0 15px;
`;
const Name = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const Down = styled.img`
	width: 10px;
	height: 7px;
	margin-left: 7px;
`;
const Option = styled.li`
	white-space: nowrap;
	padding: 13px 18px;
	cursor: pointer;
	&:hover {
		background: rgba(110, 120, 146, 0.15);
	}
	& p {
		height: 100%;
		width: 100%;
	}
`;
const Menu = styled.ul`
	border-radius: 8px;
	top: 65px;
	left: 0;
	box-shadow: 0 1px 8px rgba(110, 120, 146, 0.5);
	opacity: ${p => (p.active ? 1 : 0)};
	visibility: ${p => (p.active ? 'visible' : 'hidden')};
	transform: translateY(${p => (p.active ? '0' : '-20px')});
	transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
	@media (max-width: 768px) {
		top: 54px;
	}
`;
const Button = styled.button`
	height: 50px;
	border-radius: 8px;
	width: 200px;
`;

const User = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { assignments } = useSelector(state => state);
	const ref = useRef(null);
	const { REACT_APP_IMAGE_ALT: ImageAlt, REACT_APP_MEDIA_URL: MediaUrl } =
		process.env;
	const [active, setActive] = useDetect(ref, false);
	const redirect = (e, id) => {
		e.stopPropagation();
		setActive(false);
		history.push(`/contest/${id}`);
	};
	const onClick = () => setActive(!active);
	return assignments.length ? (
		<Container className="p-relative">
			<Name onClick={onClick}>
				<h4 className="f15 f600 margin-0">{t('app.contest')}</h4>
				<Down alt={ImageAlt} src={ArrowDown} />
			</Name>
			<Menu
				className="bg-white p-absolute padding-0 margin-0 overflow-0"
				ref={ref}
				active={active}>
				{assignments.map(({ name, id }, index) => (
					<Option
						key={index}
						className="f600 f15 bg-0 trans-5"
						onClick={e => redirect(e, id)}>
						<p className="margin-0">{name}</p>
					</Option>
				))}
			</Menu>
		</Container>
	) : null;
};
export default User;
