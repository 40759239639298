import i18next from 'i18next';
import React from 'react';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import 'rodal/lib/rodal.css';
import App from './app.jsx';
import './i18n';
import './index.css';
import store from './redux/store';
import './style.css';

render(
	<Provider store={store}>
		<I18nextProvider i18n={i18next}>
			<App />
		</I18nextProvider>
	</Provider>,
	document.getElementById('root'),
);
