import styled, { keyframes } from 'styled-components';

const animation = keyframes`
	0% {
		width: 0;
		left: 0;
	}
	50% {
		left: 0;
		width: 100vw;
	}
	100% {
		width: 0;
		left: 100vw;
	}
`;
const Progress = styled.div`
	position: fixed;
	top: 0;
	animation-name: ${animation};
	animation-duration: 1.2s;
	animation-delay: 0;
	height: 5px;
	width: 100vw;
	z-index: 99999;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	background: var(--blue);
`;
export default Progress;
