import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Suspense from './suspense.jsx';

const Controller = ({ component: Component, type, ...rest }) => {
	const { authentication, userTaken } = useSelector(state => state);
	const { status } = useSelector(state => state.user);
	return (
		<Route
			{...rest}
			render={props =>
				userTaken ? (
					type === 'authentication' ? (
						authentication ? (
							<Redirect to="/" />
						) : (
							<Component {...props} />
						)
					) : type === 'profile' ? (
						authentication ? (
							<Component {...props} />
						) : (
							<Redirect to="/sign-in" />
						)
					) : type === 'check-profile' ? (
						authentication && status === 1 ? (
							<Component {...props} />
						) : (
							<Redirect to="/profile" />
						)
					) : (
						<Component {...props} />
					)
				) : (
					<Suspense />
				)
			}
		/>
	);
};
export default Controller;
