import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Timer = ({ difference, setState }) => {
	const { t } = useTranslation();
	const [time, setTime] = useState(difference);
	const day = parseInt(time / (1000 * 60 * 60 * 24));
	const dayRemain = time % (1000 * 60 * 60 * 24);
	const hour = parseInt(dayRemain / (1000 * 60 * 60));
	const hourRemain = parseInt(dayRemain % (1000 * 60 * 60));
	const minute = parseInt(hourRemain / (1000 * 60));
	const minuteRemain = parseInt(hourRemain % (1000 * 60));
	const second = parseInt(minuteRemain / 1000);
	useEffect(() => {
		const tick = () => {
			if (time > 0) {
				setTime(time - 1000);
			} else {
				setState(3);
			}
		};
		const timer = setInterval(_ => tick(), 1000);
		return () => clearInterval(timer);
	}, [time]);
	return (
		<div className="f16 f600">
			{day.toString().padStart(2, '0')} : {hour.toString().padStart(2, '0')} :{' '}
			{minute.toString().padStart(2, '0')} :{' '}
			{second.toString().padStart(2, '0')}
		</div>
	);
};
export default Timer;
